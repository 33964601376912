import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";

import { clsx } from "clsx";

import { FormEvent, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAccount } from "@/api/hooks/useAccount.ts";
import {
  ChangeEmailRequest,
  ChangePasswordRequest,
} from "@/api/rest/models/auth";
import { changeEmail, changePassword } from "@/api/rest/services/authService";
import AccountsTable from "@/components/AccountsTable.tsx";
import LanguageSelector from "@/components/LanguageSelector";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export interface BannerState {
  message: string;
  type: "success" | "error";
}

export function AccountPage() {
  // const households = useHouseholds((state) => state.households);
  const account = useAccount();

  const [passwordChangeResult, setPasswordChangeResult] =
    useState<BannerState | null>(null);
  const [emailChangeResult, setEmailChangeResult] =
    useState<BannerState | null>(null);
  const { t } = useTranslation();

  const submitChangePassword = async (e: FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    setPasswordChangeResult(null);
    e.preventDefault();
    const data = Object.fromEntries(
      new FormData(form).entries(),
    ) as unknown as ChangePasswordRequest; // TODO: proper form validation
    if (!data.newPassword || data.newPassword !== data.confirmPassword) {
      setPasswordChangeResult({
        message: t("account.passwordsNotSame"),
        type: "error",
      });
      return;
    }
    try {
      const result = await changePassword(data);
      if (result) {
        setPasswordChangeResult({
          message: t("account.successfulPasswordChange"),
          type: "success",
        });
        form.reset();
      }
    } catch (e) {
      setPasswordChangeResult({ message: String(e), type: "error" });
    }
  };

  const submitChangeEmail = async (e: FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    setEmailChangeResult(null);
    e.preventDefault();
    const data = Object.fromEntries(
      new FormData(form).entries(),
    ) as unknown as ChangeEmailRequest; // TODO: proper form validation
    if (!data.newEmail) {
      setEmailChangeResult({
        message: t("account.emailCannotBeEmpty"),
        type: "error",
      });
      return;
    }
    const result = await changeEmail(data);
    if (result.success) {
      setEmailChangeResult({
        message: t("account.successfulEmailChange"),
        type: "success",
      });
      form.reset();
    } else {
      setEmailChangeResult({ message: result.message, type: "error" });
    }
  };

  useDebugLifecycle("AccountPage", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <main className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white">
      <div className="flex flex-end flex-row-reverse">
        <LanguageSelector />
      </div>
      <div className="flex flex-row justify-center">
        <UserCircleIcon className="h-24 w-24 inline-block mr-2" />
        <div className="flex flex-col justify-center">
          <h2 className="text-2xl font-bold">{account?.name}</h2>
          <span className="text-lg text-gray-500">{account?.email}</span>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row pb-8 gap-4 mt-8">
        <div className="flex flex-col flex-grow lg:basis-1/2 p-4 rounded-lg bg-white shadow-md">
          <form
            className="flex flex-col gap-4 self-stretch"
            onSubmit={submitChangePassword}
          >
            <h3 className="text-2xl font-bold">
              {t("account.changePassword")}
            </h3>
            <input
              className="border border-gray-300 rounded-md p-2"
              type="password"
              name="currentPassword"
              autoComplete="current-password"
              placeholder={t("account.currentPassword")}
              required
            />
            <input
              className="border border-gray-300 rounded-md p-2"
              type="password"
              name="newPassword"
              autoComplete="new-password"
              placeholder={t("account.newPassword")}
              required
            />
            <input
              className="border border-gray-300 rounded-md p-2"
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              placeholder={t("account.confirmNewPassword")}
              required
            />
            {passwordChangeResult && (
              <span
                className={clsx(
                  `rounded-lg px-4 py-2 text-center`,
                  passwordChangeResult.type === "success"
                    ? `bg-green-300 text-green-900`
                    : `bg-red-300 text-red-900`,
                )}
              >
                {passwordChangeResult.message}
              </span>
            )}
            {passwordChangeResult?.type !== "success" && (
              <button className="rounded-md p-2">
                {t("account.changePassword")}
              </button>
            )}
          </form>
        </div>
        <div className="flex flex-col flex-grow lg:basis-1/2 gap-4">
          <form
            className="flex flex-col gap-4 self-stretch bg-white shadow-md rounded-lg p-4"
            onSubmit={submitChangeEmail}
          >
            <h3 className="text-2xl font-bold">{t("account.changeEmail")}</h3>
            <input
              className="border border-gray-300 rounded-md p-2"
              type="email"
              name="newEmail"
              autoComplete="email"
              placeholder={t("account.newEmail")}
              required
            />
            {emailChangeResult && (
              <span
                className={clsx(
                  `rounded-lg px-4 py-2 text-center`,
                  emailChangeResult.type === "success"
                    ? `bg-green-300 text-green-900`
                    : `bg-red-300 text-red-900`,
                )}
              >
                {emailChangeResult.message}
              </span>
            )}
            {emailChangeResult?.type !== "success" && (
              <button className="rounded-md p-2">
                {t("account.changeEmail")}
              </button>
            )}
          </form>
          <div className="flex flex-col p-4 rounded-lg bg-white shadow-md">
            <h3 className="text-2xl font-bold pb-4">
              {t("account.myConnections")}
            </h3>
            <AccountsTable
              accounts={account?.accounts ?? []}
              showAddAccount={false}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
