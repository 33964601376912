import * as Sentry from "@sentry/react";

import posthog from "posthog-js";

import dayjs from "@/components/dayjsExtended";
import i18n from "@/i18n";

import { apiBase, fetchUsingApi } from "../core/apiClient";
import { ApiResponse } from "../core/types";
import { AccountInfo, AccountInfoRaw } from "../models/account";

const convertAccountInfo = (data: AccountInfoRaw): AccountInfo => {
  return {
    ...data,
    createdAtTimestamp: dayjs(data.createdAt),
  };
};

export const fetchAccountInfo = async (): Promise<ApiResponse<AccountInfo>> => {
  const result = await fetchUsingApi(
    `${apiBase}/account/v1/info`,
    "GET",
    convertAccountInfo,
  );
  if (result.success) {
    try {
      Sentry.setUser({
        id: result.result.id.toString(),
        email: result.result.email,
        name: result.result.name,
      });
      posthog.identify(result.result.id.toString(), {
        email: result.result.email,
        name: result.result.name,
      });
      await i18n.changeLanguage(result.result.language);
    } catch {
      // ignore
    }
  }
  return result;
};
