import { Outlet } from "react-router-dom";

import Navbar from "@/components/Navbar";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export default function LoggedInPage() {
  useDebugLifecycle("HouseHoldPage", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <div className="h-screen w-screen overflow-y-auto md:overflow-hidden flex flex-col md:flex-row justify-stretch">
      <Navbar />
      <Outlet />
    </div>
  );
}
