import { apiBase, fetchUsingApi } from "../core/apiClient";
import { ApiResponse } from "../core/types";
import { Product, ProductUpdateRequest } from "../models/product";
import { enrichProduct } from "../utils";

export const updateProduct = async (
  productId: number,
  data: ProductUpdateRequest,
): Promise<ApiResponse<Product>> => {
  return await fetchUsingApi(
    `${apiBase}/product/v1/${productId}/update`,
    "POST",
    enrichProduct,
    JSON.stringify(data),
  );
};

export const createProduct = async (
  householdId: number,
  data: ProductUpdateRequest,
): Promise<ApiResponse<Product>> => {
  return await fetchUsingApi(
    `${apiBase}/product/v1/create?householdId=${householdId}`,
    "POST",
    enrichProduct,
    JSON.stringify(data),
  );
};

export const deleteProduct = async (
  productId: number,
): Promise<ApiResponse<void>> => {
  return await fetchUsingApi(
    `${apiBase}/product/v1/${productId}/delete`,
    "DELETE",
    () => undefined,
  );
};
