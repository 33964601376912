import { useCallback, useEffect, useState } from "react";

import { HouseholdData } from "../rest/models/household";
import { fetchHouseholdData } from "../rest/services/householdService";

export const useHousehold = (householdId: number | null) => {
  const [householdData, setHouseholdData] = useState<HouseholdData>();
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!householdId) {
      return;
    }
    setLoading(true);
    const data = await fetchHouseholdData(householdId);
    setLoading(false);
    if (data.success) {
      setHouseholdData(data.result);
    }
  }, [householdId]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, householdId]);

  return [householdData, fetchData, loading] as const;
};
