import { useEffect } from "react";
import { create } from "zustand";

import { HouseholdWithOwnership } from "../rest/models/household";
import { fetchAllHouseholds } from "../rest/services/householdService";

interface HouseholdsState {
  households: HouseholdWithOwnership[];
}

interface HouseholdActions {
  load: () => Promise<void>;
  clear: () => void;
}

export const useHouseholdsGlobal = create<HouseholdsState & HouseholdActions>(
  (set) => {
    return {
      households: [],
      load: async () => {
        const result = await fetchAllHouseholds();
        if (result.success) {
          set({ households: result.result.toSorted((a, b) => a.id - b.id) });
        }
      },
      clear: () => {
        set({ households: [] });
      },
    };
  },
);

export const useHouseholds = () => {
  const households = useHouseholdsGlobal((state) => state.households);
  const load = useHouseholdsGlobal((state) => state.load);

  useEffect(() => {
    if (households.length < 1) {
      load().then(null);
    }
  }, [households, load]);

  return households;
};
