import { apiBase, fetchUsingApi } from "../core/apiClient";
import { ApiResponse } from "../core/types";

export interface EntityConfiguration {
  id: number;
  type: EntityConfigurationType;
  entityType: EntityConfigurationEntityType;
  entityId: number;
  value: string;
}
export enum EntityConfigurationEntityType {
  BUILDING = "BUILDING",
  HOUSEHOLDER = "HOUSEHOLDER",
}
export enum EntityConfigurationType {
  QUESTIONS_PROMPT = "QUESTIONS_PROMPT",
  WARRANTY_PROMPT = "WARRANTY_PROMPT",
  WELCOME_EMAIL = "WELCOME_EMAIL",
  HAUSING_CLIENT_ID = "HAUSING_CLIENT_ID",
  HAUSING_CLIENT_SECRET = "HAUSING_CLIENT_SECRET",
  HAUSING_COMPANY_ID = "HAUSING_COMPANY_ID",
  HAUSING_BUILDING_ID = "HAUSING_BUILDING_ID",
  HAUSING_ROOM_ID = "HAUSING_ROOM_ID",
}

export const getBuildingConfiguration = async (
  buildingId: number,
): Promise<ApiResponse<EntityConfiguration[]>> => {
  return await fetchUsingApi(
    `${apiBase}/configuration/v1/list?buildingId=${buildingId}`,
    "GET",
    (e) => e as EntityConfiguration[],
  );
};

export interface ConfigurationUpdateRequest {
  buildingId: number;
  configurationType: EntityConfigurationType;
  configurationValue: string;
}

export const updateConfiguration = async (
  request: ConfigurationUpdateRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/configuration/v1/update`,
    "POST",
    (e) => e as boolean,
    JSON.stringify(request),
  );
};
