import { FormEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { LoginRequest } from "@/api/rest/models/auth";
import { login } from "@/api/rest/services/authService";
import { getToken } from "@/utils/tokenManager";

export default function LoginForm({ className }: { className?: string }) {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  // Add this useEffect
  useEffect(() => {
    // Check for token - adjust this according to how you store your token
    const token = getToken();

    if (token !== null) {
      navigate("/household");
    }
  }, [navigate]);

  const handleEmailLogin = async (e: FormEvent<HTMLFormElement>) => {
    setError(null);
    e.preventDefault();
    const data = Object.fromEntries(
      new FormData(e.currentTarget).entries(),
    ) as unknown as LoginRequest; // TODO: proper form validation
    try {
      const result = await login(data);
      if (result) {
        navigate("/household");
      }
    } catch (e) {
      setError(String(e));
    }
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <form className={className} onSubmit={handleEmailLogin}>
      <input
        className="border border-gray-300 rounded-md p-2"
        name="email"
        autoComplete="username"
        type="email"
        placeholder={t("login.email")}
        required
      />
      <input
        className="border border-gray-300 rounded-md p-2"
        name="password"
        autoComplete="current-password"
        type="password"
        placeholder={t("login.password")}
        required
      />
      {error !== null && (
        <span className="bg-red-300 rounded-lg p-4 text-center text-red-900 text-md">
          {error}
        </span>
      )}
      <button className="rounded-md p-2">{t("login.login")}</button>
      {error !== null && (
        <a
          className="text-dobu-orange hover:text-dobu-orange-focus font-bold text-center cursor-pointer"
          onClick={goToForgotPassword}
        >
          {t("login.forgotPassword")}
        </a>
      )}
    </form>
  );
}
