import { useTranslation } from "react-i18next";

export default function AboutForm({ className }: { className?: string }) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <span className="mt-2 text-center">{t("login.aboutInfo")}</span>
      <span className="mt-2 text-center">
        {t("login.wantToTry")}{" "}
        <a
          className="text-dobu-orange hover:text-dobu-orange-focus font-bold text-center cursor-pointer"
          href="mailto:info@dobu.ee"
        >
          info@dobu.ee
        </a>
      </span>
    </div>
  );
}
