import { DocumentTextIcon } from "@heroicons/react/24/outline";

import { clsx } from "clsx";

import { useTranslation } from "react-i18next";

import { Document } from "@/api/rest/models/document";
import { Product } from "@/api/rest/models/product";
import { downloadDocument2 } from "@/api/rest/services/documentService";
import { KeyV } from "@/components/common/KeyV.tsx";
import dayjs from "@/components/dayjsExtended.ts";
import DocumentsPreview from "@/components/household-contents/DocumentsPreview.tsx";
import MaintenanceButton from "@/components/household-contents/MaintenanceButton.tsx";

export default function ProductOverview({
  product,
  documents,
  updateHousehold,
}: {
  product: Product;
  documents: Document[];
  updateHousehold: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="p-6 flex flex-col gap-6 overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100">
      {(product.manufacturer ||
        product.code ||
        product.nextMaintenanceTimestamp) && (
        <div className="flex flex-row gap-4 flex-wrap">
          {product.manufacturer && (
            <KeyV
              name={t("product.manufacturer")}
              className="flex-grow md:flex-grow-0"
              value={product.manufacturer}
            />
          )}
          {product.code && (
            <KeyV
              name={t("product.productNumber")}
              className="flex-grow md:flex-grow-0"
              value={product.code}
            />
          )}
          {product.nextMaintenanceTimestamp && (
            <MaintenanceButton
              key={product.id}
              className={clsx(
                "flex flex-col py-2 px-4 flex-shrink-0 rounded-lg border-2 hover:bg-orange-100",
                product.nextMaintenanceTimestamp.isBefore(
                  dayjs().add(7, "days"),
                )
                  ? product.nextMaintenanceTimestamp.isBefore(dayjs())
                    ? "border-red-500 bg-red-50"
                    : "border-yellow-500 bg-yellow-50"
                  : "bg-white border-gray-300",
              )}
              product={product}
              documents={[]}
              updateHousehold={updateHousehold}
            >
              <div className="flex flex-row items-center">
                <span className="font-semibold">{product.maintenanceName}</span>
              </div>
              <span>{`${product.nextMaintenanceTimestamp.format("L")} (${t(
                "product.everyNumberOfDays",
                { interval: product.maintenanceIntervalDays },
              )})`}</span>
            </MaintenanceButton>
          )}
        </div>
      )}
      {product.information && (
        <KeyV name={t("product.extraInfo")} value={product.information} />
      )}
      {documents && documents.length > 0 && (
        <div className="flex flex-col">
          <b className="mb-2">{t("product.relatedDocuments")}</b>
          <div className="flex flex-col md:flex-row items-stretch md:items-start flex-wrap gap-2">
            {documents.map((document) => (
              <DocumentsPreview
                key={document.id}
                document={document}
                onSelect={downloadDocument2}
                allowPreview
              >
                <KeyV
                  key={document.id}
                  name={document.fileName}
                  forceOnClick
                  icon={
                    <DocumentTextIcon className="h-4 w-4 -mt-1 inline-block" />
                  }
                />
              </DocumentsPreview>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
