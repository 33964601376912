import { useMemo } from "react";
import { groupBy } from "lodash";

import { useAccount } from "./useAccount.ts";
import { useHouseholds } from "./useHouseholds.ts";

export const useEnrichedHouseholds = () => {
  const households = useHouseholds();
  const account = useAccount();

  return useMemo(() => {
    const householdsByBuilding = groupBy(
      households,
      (household) => household.buildingId,
    );
    const managedBuildings =
      account?.accounts.flatMap((account) => {
        return account.managedBuildings.map((building) => {
          return {
            ...building,
            households: householdsByBuilding[building.id] ?? [],
          };
        });
      }) ?? [];
    const managedBuildingIds = new Set(
      managedBuildings.map((building) => building.id),
    );
    const householdsWithoutBuilding = households.filter(
      (household) => !managedBuildingIds.has(household.buildingId),
    );
    if (householdsWithoutBuilding.length > 0) {
      return [
        {
          id: -1,
          street: null,
          households: householdsWithoutBuilding,
        },
        ...managedBuildings,
      ];
    }
    return managedBuildings;
  }, [households, account]);
};
