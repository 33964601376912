import { GoogleOAuthProvider } from "@react-oauth/google";

import * as Sentry from "@sentry/react";

import { PostHogProvider } from "posthog-js/react";

import React from "react";

import ReactDOM from "react-dom/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { setRouterInstance } from "@/api/rest/core/apiClient";
import { AccountPage } from "@/app/account/AccountPage";
import BuildingPage from "@/app/building/BuildingPage.tsx";
import BuildingSettingsPage from "@/app/building/BuildingSettingsPage.tsx";
import HomePage from "@/app/household/HomePage.tsx";
import WarrantyPage from "@/app/household/warranty/WarrantyPage.tsx";
import { AuthType } from "@/app/login/LoginType.ts";
import WarrantyCasePage from "@/app/warranty/WarrantyCasePage.tsx";

import Household from "./app/household/HouseholdPage.tsx";
import LoggedInPage from "./app/household/LoggedInPage.tsx";
import ProductEdit from "./app/household/product/ProductEditPage.tsx";
import Login from "./app/login/LoginPage.tsx";
import "./i18n";
import "./index.css";

Sentry.init({
  dsn:
    import.meta.env.MODE === "production"
      ? (import.meta.env.VITE_SENTRY_DSN as string)
      : undefined,
  enabled: import.meta.env.MODE === "production",
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //     maskAllText: false,
    //     blockAllMedia: false,
    // }),
  ],
  // Tracing
  tracePropagationTargets: ["api.dobu.ee"],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter([
  { path: "/", element: <Login authType={AuthType.LOGIN} /> },
  {
    path: "/login",
    element: <Login authType={AuthType.LOGIN} />,
  },
  {
    path: "/login/about",
    element: <Login authType={AuthType.ABOUT} />,
  },
  {
    path: "/reset-password",
    element: <Login authType={AuthType.RESET_PASSWORD} />,
  },
  {
    path: "/forgot-password",
    element: <Login authType={AuthType.FORGOT_PASSWORD} />,
  },
  {
    path: "/change-email",
    element: <Login authType={AuthType.CHANGE_EMAIL} />,
  },
  {
    path: "/household/",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: ":householdId",
        element: <Household />,
      },
      {
        path: ":householdId/product/:productId",
        element: <ProductEdit mode={"update"} />,
      },
      {
        path: ":householdId/product/new",
        element: <ProductEdit mode={"create"} />,
      },
      {
        path: ":householdId/warranty",
        element: <WarrantyPage />,
      },
    ],
  },
  {
    path: "/warranty/",
    element: <LoggedInPage />,
    children: [
      {
        path: ":warrantyCaseId",
        element: <WarrantyCasePage />,
      },
    ],
  },
  {
    path: "/building/",
    element: <LoggedInPage />,
    children: [
      {
        path: ":buildingId",
        element: <BuildingPage />,
      },
      {
        path: ":buildingId/settings",
        element: <BuildingSettingsPage />,
      },
    ],
  },
  {
    path: "/account/",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <AccountPage />,
      },
    ],
  },
  {
    path: "/not-found",
    element: <LoggedInPage />,
    children: [
      {
        path: "",
        element: <div className="text-xl">Not found</div>,
      },
    ],
  },
]);

setRouterInstance(router);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_KEY as string}
      options={{ api_host: "https://ph.dobu.ee" }}
    >
      <GoogleOAuthProvider clientId="338357809874-90emau8kl6hhg24p1aphpqo7v25n7po1.apps.googleusercontent.com">
        <RouterProvider router={router} />
      </GoogleOAuthProvider>
    </PostHogProvider>
  </React.StrictMode>,
);
