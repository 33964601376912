import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  BuildingOffice2Icon,
  IdentificationIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

import { Account, AccountType } from "../api/rest/models/account";

export default function AccountsTable({
  accounts,
  unassignAccount,
  addAccount,
  showAddAccount,
}: {
  accounts: Account[];
  unassignAccount?: (accountId: number) => void;
  addAccount?: () => void;
  showAddAccount: boolean;
}) {
  const { t } = useTranslation();

  useDebugLifecycle("AccountsTable", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <table className="p-2">
      <tbody>
        {accounts.map((account) => (
          <TableRow key={account.id}>
            {account.type === AccountType.ORGANIZATION ? (
              <BuildingOffice2Icon className="w-8 h-8 p-2 rounded-full bg-gray-300" />
            ) : (
              <IdentificationIcon className="w-8 h-8 p-2 rounded-full bg-gray-300" />
            )}
            <span>{account.name}</span>
            <span>
              <span className="font-semibold">{account.country}</span>
              {account.registryCode}
            </span>
            {unassignAccount && (
              <XMarkIcon
                className="w-5 h-5 ml-auto mr-2 cursor-pointer invisible hover:visible group-hover:visible hover:text-dobu-orange-focus"
                onClick={() => unassignAccount(account.id)}
              />
            )}
          </TableRow>
        ))}
        {showAddAccount && (
          <TableRow onClick={addAccount}>
            <PlusIcon className="w-8 h-8 p-2 rounded-full bg-gray-300" />
            <span>{t("settings.addNewAccess")}</span>
            <span />
            <span />
          </TableRow>
        )}
      </tbody>
    </table>
  );
}

function TableRow({
  children,
  onClick,
}: {
  children: ReactNode[];
  onClick?: () => void;
}) {
  return (
    <tr
      className={clsx("group", onClick && "cursor-pointer")}
      onClick={onClick}
    >
      {children.map((child, index) => (
        <td
          key={index}
          className="group-hover:bg-gray-200 first:rounded-l-full last:rounded-r-full"
        >
          {child}
        </td>
      ))}
    </tr>
  );
}
