import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router-dom";

import { useBuildingConfiguration } from "@/api/hooks/useBuildingConfiguration.ts";
import { EntityConfigurationType } from "@/api/rest/services/configurationService";
import ConfigurationEditor from "@/components/building/ConfigurationEditor.tsx";

const allowedConfigurationTypes = new Set([
  EntityConfigurationType.WARRANTY_PROMPT,
  EntityConfigurationType.WELCOME_EMAIL,
]);

export default function BuildingSettingsPage() {
  const { t } = useTranslation();
  const { buildingId } = useParams<{ buildingId: string }>();
  const navigate = useNavigate();

  const [buildingConfiguration, refreshBuildingConfiguration] =
    useBuildingConfiguration(Number(buildingId));
  return (
    <main className="flex flex-grow flex-col p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white items-center">
      <div className="flex flex-grow self-stretch flex-col">
        <div className="flex flex-row ">
          <ChevronLeftIcon
            className="h-9 w-9 mr-2 hover:bg-slate-300 focus-visible:bg-dobu-orange cursor-pointer rounded"
            onClick={() => navigate(-1)}
          />
          <h2 className="text-3xl font-bold text-center mr-4">
            {t("settings.title")}
          </h2>
        </div>
        <div className="flex flex-col gap-8 mt-8">
          {buildingConfiguration
            ?.filter((conf) => allowedConfigurationTypes.has(conf.type))
            .map((conf) => (
              <ConfigurationEditor
                conf={conf}
                key={conf.id}
                refreshConf={refreshBuildingConfiguration}
              />
            ))}
        </div>
      </div>
    </main>
  );
}
