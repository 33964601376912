import { HomeModernIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";
import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";

import { Household } from "@/api/rest/models/household";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

type NavbarMenuProps = {
  isOpen: boolean;
  account: any; // Replace with proper type
  households: any[]; // Replace with proper type
  onNavigate: (path: string) => void;
  onLogout: () => void;
};

export const NavbarMenu = memo(function NavbarMenu({
  isOpen,
  account,
  households,
  onNavigate,
  onLogout,
}: NavbarMenuProps) {
  const { t } = useTranslation();

  useDebugLifecycle("Navbar", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <div
      className={clsx(
        "md:flex flex-col justify-stretch pb-8 md:overflow-y-scroll scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 md:flex-grow",
        isOpen ? "flex" : "hidden",
      )}
    >
      {account && (
        <span
          className="px-4 py-2 font-bold text-lg cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus"
          onClick={() => onNavigate(`/account`)}
        >
          <UserCircleIcon className="h-6 w-6 inline-block -mt-1 mr-2" />
          {account.name}
        </span>
      )}
      {households.map((building) => (
        <Fragment key={building.id}>
          <span
            className="px-4 py-2 font-bold text-lg cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus"
            onClick={() => {
              if (building.id !== -1) {
                onNavigate(`/building/${building.id}`);
              } else {
                onNavigate(`/household`);
              }
            }}
          >
            <HomeModernIcon className="h-6 w-6 inline-block -mt-1 mr-2" />
            {building.id !== -1 ? building.street : t("home.myResidences")}
          </span>
          <ul>
            {building.households.map((household: Household) => (
              <li
                className="pl-12 pr-8 py-1 cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-dobu-orange-focus"
                key={household.id}
                onClick={() => onNavigate(`/household/${household.id}`)}
              >
                {household.title}
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
      <span className="hidden md:flex flex-grow" />
      <div className="flex flex-row justify-center mt-3">
        <button
          className="rounded-md px-4 py-1 !border-dobu-black border-2 !bg-white hover:!bg-gray-200 !text-dobu-black text-sm"
          onClick={onLogout}
        >
          {t("login.logout")}
        </button>
      </div>
    </div>
  );
});
