import { useState } from "react";

import { useTranslation } from "react-i18next";

import { HouseholdData } from "@/api/rest/models/household";
import { createRoom, deleteRoom } from "@/api/rest/services/roomService";
import ManagedPopup from "@/components/common/ManagedPopup.tsx";

export default function RoomEditPopup({
  householdData,
  updateHousehold,
}: {
  householdData: HouseholdData;
  updateHousehold: () => Promise<void>;
}) {
  const [newRoom, setNewRoom] = useState<string>("");
  const { t } = useTranslation();

  const removeRoom = async (roomId: number) => {
    await deleteRoom(householdData.household.id, roomId);
    await updateHousehold();
  };

  const addRoom = async () => {
    if (!newRoom) {
      return;
    }
    await createRoom(householdData.household.id, newRoom);
    setNewRoom("");
    await updateHousehold();
  };

  return (
    <ManagedPopup
      minHeight={false}
      trigger={t("room.change")}
      title={t("room.change")}
      triggerClassname="pl-4 md:invisible group-hover:visible font-semibold text-sm text-gray-500 hover:text-dobu-orange-focus cursor-pointer"
    >
      <div className="p-4 flex flex-col overflow-y-auto">
        {householdData.rooms.map((room) => (
          <div
            key={room.id}
            className="flex flex-row justify-between items-center hover:bg-gray-300 p-2 rounded"
          >
            <span className="font-semibold">{room.name}</span>
            <button
              className="rounded px-2"
              onClick={() => removeRoom(room.id)}
            >
              {t("room.delete")}
            </button>
          </div>
        ))}
        <div className="flex flex-row items-stretch p-2 gap-2">
          <input
            className="flex flex-grow border border-gray-300 rounded-md p-1 w-full"
            name="roomName"
            type="text"
            value={newRoom}
            onChange={(e) => setNewRoom(e.target.value)}
            placeholder={t("room.newRoomName")}
            required
          />
          <button className="rounded px-2 text-nowrap" onClick={addRoom}>
            {t("room.add")}
          </button>
        </div>
      </div>
    </ManagedPopup>
  );
}
