import {
  CheckCircleIcon,
  InformationCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useHouseholds } from "@/api/hooks/useHouseholds.ts";
import { HouseholdWithOwnership } from "@/api/rest/models/household";
import { WarrantyCase, WarrantyCaseStatus } from "@/api/rest/models/warranty";

export default function ListClaims({
  cases,
  showHouseholds = false,
}: {
  cases: WarrantyCase[];
  showHouseholds?: boolean;
}) {
  const navigate = useNavigate();
  const households = useHouseholds();
  const householdsbyId = useMemo(() => {
    return households.reduce(
      (acc, h) => {
        acc[h.id] = h;
        return acc;
      },
      {} as Record<number, HouseholdWithOwnership>,
    );
  }, [households]);
  const { t } = useTranslation();

  const statusOrder = useMemo(
    () => ({
      [WarrantyCaseStatus.CLOSED]: 3,
      [WarrantyCaseStatus.SOLVED]: 2,
      [WarrantyCaseStatus.QUESTION_SENT]: 1,
      [WarrantyCaseStatus.RESPONSE_RECEIVED]: 1,
    }),
    [],
  ); // Empty dependency array since this object never changes

  const sortedCases = useMemo(() => {
    return cases
      .slice()
      .sort(
        (a, b) => (statusOrder[a.status] || 0) - (statusOrder[b.status] || 0),
      );
  }, [cases, statusOrder]);

  return (
    <>
      <h3 className="text-xl font-semibold">
        {t("warranty.submittedWarrantyCases")}
      </h3>
      {sortedCases?.map((c) => (
        <div
          key={c.id}
          className={`flex flex-col cursor-pointer rounded-md py-2 px-4 ${
            showHouseholds && c.status === WarrantyCaseStatus.QUESTION_SENT
              ? "bg-orange-200 hover:bg-orange-300"
              : "bg-white hover:bg-orange-100"
          }`}
          onClick={() => navigate(`/warranty/${c.id}`)}
        >
          {showHouseholds && (
            <span>
              <span className="font-bold">
                {householdsbyId[c.householdId].title}:
              </span>{" "}
              {c.title}
            </span>
          )}
          {!showHouseholds && <span className="font-bold">{c.title}</span>}
          <div className="flex flex-row text-gray-500 text-sm items-center">
            {c.status === WarrantyCaseStatus.SOLVED && (
              <CheckCircleIcon className="h-4 w-4 mr-1 inline text-green-500" />
            )}
            {c.status === WarrantyCaseStatus.RESPONSE_RECEIVED &&
              !showHouseholds && (
                <InformationCircleIcon className="h-4 w-4 mr-1 inline text-dobu-orange" />
              )}
            {c.status === WarrantyCaseStatus.QUESTION_SENT &&
              showHouseholds && (
                <InformationCircleIcon className="h-4 w-4 mr-1 inline text-dobu-orange" />
              )}
            {c.status === WarrantyCaseStatus.CLOSED && (
              <MinusCircleIcon className="h-4 w-4 mr-1 inline text-red-600" />
            )}
            <span>{t(c.localisedStatus)}</span>
            <span className="flex-grow" />
            <span>{c.createdAtTimestamp.format("L")}</span>
          </div>
        </div>
      ))}
      {cases?.length === 0 && (
        <div className="text-gray-500">{t("warranty.noWarrantyCases")}</div>
      )}
    </>
  );
}
