import { apiBase, fetchUsingApi } from "@/api/rest/core/apiClient";
import { ApiResponse } from "@/api/rest/core/types";
import {
  NewWarrantyCaseRequest,
  NewWarrantyCaseResponse,
  SubmitWarrantyCaseRequestWithoutFiles,
  UpdateWarrantyStatusRequest,
  WarrantyCase,
  WarrantyCaseMessage,
  WarrantyCaseMessageRaw,
  WarrantyCaseRaw,
  WarrantyCaseResponse,
  WarrantyMessageRequest,
  statusMap,
} from "@/api/rest/models/warranty";
import dayjs from "@/components/dayjsExtended";

const enrichWarrantyCaseResponse = (
  response: WarrantyCaseResponse,
): WarrantyCaseResponse => {
  return {
    case: enrichWarrantyCase([response.case])[0],
    messages: enrichWarrantyCaseMessages(response.messages),
  };
};

const enrichWarrantyCaseMessage = (
  m: WarrantyCaseMessageRaw,
): WarrantyCaseMessage => {
  return {
    ...m,
    createdAtTimestamp: dayjs(m.createdAt),
  };
};

const enrichWarrantyCaseMessages = (
  messages: WarrantyCaseMessageRaw[],
): WarrantyCaseMessage[] => {
  return messages.map(enrichWarrantyCaseMessage);
};

const enrichWarrantyCase = (cases: WarrantyCaseRaw[]): WarrantyCase[] => {
  return cases.map((c) => ({
    ...c,
    createdAtTimestamp: dayjs(c.createdAt),
    localisedStatus: statusMap.get(c.status) ?? c.status.toString(),
  }));
};

export const getAllCases = async (
  householdId: number,
): Promise<ApiResponse<WarrantyCase[]>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/list?householdId=${householdId}`,
    "GET",
    enrichWarrantyCase,
  );
};

export const getAllCasesForBuilding = async (
  buildingId: number,
): Promise<ApiResponse<WarrantyCase[]>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/list/building?buildingId=${buildingId}`,
    "GET",
    enrichWarrantyCase,
  );
};

export const getCase = async (
  caseId: number,
): Promise<ApiResponse<WarrantyCaseResponse>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/get`,
    "GET",
    enrichWarrantyCaseResponse,
  );
};

export const sendMessage = async (
  caseId: number,
  request: WarrantyMessageRequest,
): Promise<ApiResponse<WarrantyCaseMessage>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/message`,
    "POST",
    enrichWarrantyCaseMessage,
    JSON.stringify(request),
  );
};

export const sendFiles = async (
  caseId: number,
  accountId: number,
  files: File[],
): Promise<ApiResponse<WarrantyCaseMessage[]>> => {
  const formData = new FormData();
  formData.append("accountId", accountId.toString());
  files.forEach((file) => {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  });
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/files`,
    "POST",
    enrichWarrantyCaseMessages,
    formData,
  );
};

export const fileNewCase = async (
  request: NewWarrantyCaseRequest,
): Promise<ApiResponse<NewWarrantyCaseResponse>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/new`,
    "POST",
    (e) => e as NewWarrantyCaseResponse,
    JSON.stringify(request),
  );
};

export const forceSubmitCase = async (
  request: SubmitWarrantyCaseRequestWithoutFiles,
  files: File[],
): Promise<ApiResponse<WarrantyCaseRaw>> => {
  const formData = new FormData();
  Object.entries(request).forEach(([key, value]) =>
    formData.append(key, (value as string | number).toString()),
  );
  files.forEach((file) => {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  });
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/submit`,
    "POST",
    (e) => e as WarrantyCaseRaw,
    formData,
  );
};

export const updateStatus = async (
  caseId: number,
  request: UpdateWarrantyStatusRequest,
): Promise<ApiResponse<WarrantyCaseRaw>> => {
  return await fetchUsingApi(
    `${apiBase}/warranty/v1/${caseId}/status`,
    "PUT",
    (e) => e as WarrantyCaseRaw,
    JSON.stringify(request),
  );
};
