import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useEffect } from "react";

import { useAccount } from "@/api/hooks/useAccount.ts";
import { useEnrichedHouseholds } from "@/api/hooks/useEnrichedHouseholds.ts";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export default function HomePage() {
  const households = useEnrichedHouseholds();
  const account = useAccount();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (households.length === 1 && households[0].households.length === 1) {
      navigate(`/household/${households[0].households[0].id}`);
    }
  }, [households, navigate]);

  useDebugLifecycle("HomePage", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <main className="flex flex-grow flex-col pt-12 p-4 md:p-12 md:overflow-y-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-white gap-6">
      {account?.name && (
        <h2 className="font-semibold text-4xl">
          {t("home.welcome")}, {account?.name}!
        </h2>
      )}
      {households.map((building) => (
        <>
          <h2 className="text-xl font-semibold">{building.street}</h2>
          <div
            key={building.id}
            className="flex flex-row flex-wrap gap-4 mb-4 justify-between"
          >
            {building.households.map((household) => (
              <div
                key={household.id}
                onClick={() => navigate(`/household/${household.id}`)}
                className="text-gray-700 text-center text-xl font-semibold hover:bg-orange-100 cursor-pointer p-6 bg-white rounded-md min-w-[160px]"
              >
                {household.title}
              </div>
            ))}
            <span className="flex-grow" />
          </div>
        </>
      ))}
    </main>
  );
}
