export const randomString = (length: number): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const isAppleMobileDevice = (): boolean => {
  return (
    /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent) &&
    navigator.maxTouchPoints > 1
  );
};

export const isMobileDevice = (): boolean => {
  return (
    navigator.userAgentData?.mobile ||
    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) &&
      navigator.maxTouchPoints > 1)
  );
};
