import { DocumentTextIcon, PaperClipIcon } from "@heroicons/react/24/outline";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { useAccount } from "@/api/hooks/useAccount.ts";
import { ApiResponse } from "@/api/rest/core/types";
import { HouseholdData } from "@/api/rest/models/household";
import {
  NewWarrantyCaseResponse,
  NewWarrantyCaseResponseType,
} from "@/api/rest/models/warranty";
import {
  fileNewCase,
  forceSubmitCase,
} from "@/api/rest/services/warrantyService.ts";
import { Banner } from "@/components/common/Banner.tsx";
import {
  ChatMessage,
  ChatMessageHolder,
  ChatMessageType,
} from "@/components/common/ChatMessage.tsx";
import Spinner from "@/components/common/Spinner.tsx";
import UploadFilesPopup from "@/components/UploadFilesPopup.tsx";

interface ChatMessageItem extends NewWarrantyCaseResponse {
  isResponse: boolean;
}

export default function NewClaim({
  householdData,
  refreshCases,
}: {
  householdData: HouseholdData;
  refreshCases: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const account = useAccount();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const caseRef = useRef<HTMLTextAreaElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);

  // TODO: There should be a selecor for the account
  const availableAccounts = useMemo(() => {
    const accountsInHousehold = householdData.accounts.map((a) => a.id);
    return (
      account?.accounts.filter((a) => accountsInHousehold.includes(a.id)) ?? []
    );
  }, [householdData, account]);

  const [chatHistory, setChatHistory] = useState<ChatMessageItem[]>([]);
  const [pendingFiles, setPendingFiles] = useState<File[]>([]);

  const submitFiles = useCallback(
    async (files: File[]): Promise<ApiResponse<unknown>> => {
      setPendingFiles([...pendingFiles, ...files]);
      return Promise.resolve({ success: true, result: null });
    },
    [pendingFiles],
  );

  const submit = useCallback(
    async (isFreshChat: boolean) => {
      const question = inputRef.current?.value ?? "";
      const selectedAccount = availableAccounts?.[0]?.id;
      if (!question || !selectedAccount) {
        return;
      }
      inputRef.current!.value = "";
      setChatHistory((oldHistory) => [
        ...oldHistory,
        {
          status: NewWarrantyCaseResponseType.MORE_INFO_REQUIRED,
          message: question,
          caseId: null,
          isResponse: false,
          provisionalTitle: null,
        },
      ]);
      const result = await fileNewCase({
        question: question,
        householdId: householdData.household.id,
        startFresh: isFreshChat,
        accountId: selectedAccount,
      });
      if (result.success) {
        setChatHistory((oldHistory) => [
          ...oldHistory,
          {
            ...result.result,
            isResponse: true,
          },
        ]);
      }
    },
    [householdData, availableAccounts, inputRef],
  );

  const resetChat = useCallback(() => {
    refreshCases();
    setChatHistory([]);
    setPendingFiles([]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    if (caseRef.current) {
      caseRef.current.value = "";
    }
  }, [refreshCases, inputRef, caseRef]);

  const FileUploadMandatoryList = {
    RETERRA_RAEMOISA: true,
    CAPITAL_MILL: true,
  };

  const [isSubmittingCase, setSubmittingState] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean | null>(null);
  const isFileUploadMandatory =
    FileUploadMandatoryList[
      householdData?.building
        .partnerCode as keyof typeof FileUploadMandatoryList
    ];

  useEffect(() => {
    if (pendingFiles.length > 0) {
      setIsFileUploaded(true);
    }
  }, [pendingFiles]);

  const submitForm = useCallback(
    async (e: unknown) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      e?.preventDefault?.();
      const value = caseRef?.current?.value ?? "";
      const selectedAccount = availableAccounts?.[0]?.id;
      if (!value || !selectedAccount) {
        return;
      }
      setSubmittingState(true);
      const result = await forceSubmitCase(
        {
          description:
            value +
            ("\n\n" +
              t("warranty.contactPersonsPhoneNumber") +
              ": " +
              phoneNumberRef.current?.value),
          householdId: householdData.household.id,
          title: titleRef.current?.value ?? "Unknown",
          accountId: selectedAccount,
          phoneNumber: phoneNumberRef.current?.value ?? null,
        },
        pendingFiles,
      );
      if (result.success) {
        // resetChat()
        navigate(`/warranty/${result.result.id}`);
      } else {
        setSubmittingState(false);
      }
    },
    [caseRef, availableAccounts, householdData, pendingFiles, navigate, t],
  );

  const currentStatus = useMemo(
    () => (chatHistory.length > 0 ? chatHistory[chatHistory.length - 1] : null),
    [chatHistory],
  );

  return (
    <div className="flex flex-grow basis-1/2 flex-col gap-4">
      <span>
        <h3 className="text-xl font-semibold inline">
          {t("warranty.submitNewCase")}
        </h3>
        {currentStatus && (
          <button
            className="ml-2 px-2 rounded !bg-slate-400 hover:!bg-slate-500"
            onClick={resetChat}
          >
            {t("warranty.cancel")}
          </button>
        )}
      </span>
      <div className="prose prose-sm prose-gray text-gray-500">
        <div>
          <ul className="m-0">
            <li className="m-0">{t("warranty.submitOneWarrantyCase")}</li>
            {isFileUploadMandatory && (
              <li className="m-0">{t("warranty.mandatoryFile")}</li>
            )}
            {isFileUploadMandatory && (
              <li className="m-0">{t("warranty.pictureDescription")}</li>
            )}
            <li>
              <span className="inline-block">
                {t("warranty.problemDescriptionInstructions")}
              </span>
              <ul className="m-0">
                <li className="m-0">{t("warranty.defectDate")}</li>
                <li className="m-0">{t("warranty.defectLocation")}</li>
                <li className="m-0">{t("warranty.defectDescription")}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {chatHistory.length > 0 && (
        <ChatMessageHolder>
          {chatHistory
            .filter(
              (m) =>
                m.status === NewWarrantyCaseResponseType.MORE_INFO_REQUIRED,
            )
            .map((m, i) => (
              <ChatMessage
                key={i}
                message={m.message}
                type={
                  m.isResponse
                    ? ChatMessageType.RESPONSE
                    : ChatMessageType.QUESTION
                }
              />
            ))}
          {currentStatus?.isResponse === false && (
            <ChatMessage
              className="animate-pulse font-bold px-4 py-1 text-lg"
              message={"..."}
              type={ChatMessageType.RESPONSE}
            />
          )}
        </ChatMessageHolder>
      )}
      {(!currentStatus ||
        currentStatus?.status ===
          NewWarrantyCaseResponseType.MORE_INFO_REQUIRED) && (
        <>
          <textarea
            className="border border-gray-300 rounded-md p-2 w-full whitespace-pre-wrap"
            name="report"
            rows={5}
            placeholder={t("warranty.describeWarrantyCase")}
            ref={inputRef}
          />
          {(!currentStatus || currentStatus?.isResponse) && (
            <button
              className="rounded-md py-2 px-4"
              onClick={() => submit(currentStatus === null)}
            >
              {currentStatus?.status ===
              NewWarrantyCaseResponseType.MORE_INFO_REQUIRED
                ? t("warranty.answer")
                : t("warranty.startSubmission")}
            </button>
          )}
        </>
      )}
      {/*{currentStatus?.status === NewWarrantyCaseResponseType.WARRANTY && <Banner success={true} message={"Juhtum on edukalt registreetirud"} />}*/}
      {/*{currentStatus?.status === NewWarrantyCaseResponseType.NO_WARRANTY && <button className="rounded-md py-2 px-4 !bg-red-700 hover:!bg-red-800">Esita juhtum siiski</button>}*/}
      {currentStatus &&
        currentStatus?.status !==
          NewWarrantyCaseResponseType.MORE_INFO_REQUIRED && (
          <>
            <span className="flex border-2" />
            {currentStatus?.status ===
              NewWarrantyCaseResponseType.NO_WARRANTY && (
              <Banner success={false} message={t("warranty.noWarrantyCase")} />
            )}
            {currentStatus?.status === NewWarrantyCaseResponseType.WARRANTY && (
              <Banner success message={t("warranty.descriptionIsSufficient")} />
            )}
            <form
              className="flex self-stretch flex-col gap-4"
              onSubmit={submitForm}
            >
              <input
                className="hidden"
                type="hidden"
                value={currentStatus.provisionalTitle ?? "Unknown"}
                ref={titleRef}
              />
              <textarea
                className="border border-gray-300 rounded-md p-2 w-full"
                name="report"
                rows={5}
                placeholder={t("warranty.caseDescription")}
                defaultValue={currentStatus.message}
                ref={caseRef}
              />
              {pendingFiles.length > 0 && (
                <div className="flex flex-row flex-wrap gap-2">
                  {pendingFiles.map((file, i) => (
                    <span key={i} className="rounded-md py-2 px-4 bg-gray-200">
                      <DocumentTextIcon className="w-4 h-4 inline -mt-0.5 mr-2" />
                      {file.name}
                    </span>
                  ))}
                </div>
              )}
              {isFileUploaded === false && (
                <Banner
                  success={false}
                  message={t("warranty.noFilesUploaded")}
                />
              )}
              <UploadFilesPopup
                className="flex flex-row"
                uploadCallback={submitFiles}
              >
                <button
                  className="rounded-md py-2 px-4 flex-grow !bg-orange-200 hover:!bg-orange-300 !text-orange-950"
                  onClick={(event) => event.preventDefault()}
                >
                  <PaperClipIcon className="w-4 h-4 inline -mt-1 ml-4 mr-1" />
                  {t("warranty.addFiles")}
                  <span className="w-4 h-4 inline ml-1 mr-4" />
                </button>
              </UploadFilesPopup>
              <input
                className="p-2 rounded"
                type="string"
                minLength={7}
                ref={phoneNumberRef}
                placeholder={t("warranty.contactPersonsPhoneNumber")}
                required
              />
              {!isSubmittingCase &&
                (isFileUploadMandatory && isFileUploaded === false ? (
                  <button
                    key="next"
                    type="button"
                    onClick={() => setIsFileUploaded(pendingFiles.length > 0)}
                    className="rounded-md py-2 px-4"
                  >
                    {t("warranty.submitCase")}
                  </button>
                ) : (
                  <button
                    key="submit"
                    type="submit"
                    className="rounded-md py-2 px-4"
                  >
                    {t("warranty.submitCase")}
                  </button>
                ))}
              {isSubmittingCase && (
                <span className="bg-orange-100 text-center rounded-md py-2 px-4">
                  <Spinner className="w-5 h-5 inline mr-2" />
                  {t("warranty.sendingCase")}
                </span>
              )}
            </form>
          </>
        )}
    </div>
  );
}
