import { clsx } from "clsx";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { ForgotPasswordRequest } from "@/api/rest/models/auth";
import { forgotPassword } from "@/api/rest/services/authService";
import { BannerState } from "@/app/account/AccountPage.tsx";

export default function ForgotPasswordForm({
  className,
}: {
  className?: string;
}) {
  const { t } = useTranslation();

  const [forgotResult, setForgotResult] = useState<BannerState | null>(null);

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    setForgotResult(null);
    e.preventDefault();
    const data = Object.fromEntries(
      new FormData(e.currentTarget).entries(),
    ) as unknown as ForgotPasswordRequest; // TODO: proper form validation
    try {
      const result = await forgotPassword(data);
      if (result) {
        setForgotResult({
          type: "success",
          message: t("login.resetPasswordFormSuccess"),
        });
      }
    } catch (e) {
      setForgotResult({ type: "error", message: String(e) });
    }
  };

  return (
    <form className={className} onSubmit={submitForm}>
      <span className="text-gray-500 text-center">
        {t("login.passwordResetInstructions")}
      </span>
      <input
        className="border border-gray-300 rounded-md p-2"
        name="email"
        autoComplete="username"
        type="email"
        placeholder={t("login.email")}
        required
      />
      {forgotResult && (
        <span
          className={clsx(
            `rounded-lg p-4 text-center text-md`,
            forgotResult.type === "success"
              ? `bg-green-300 text-green-900`
              : `bg-red-300 text-red-900`,
          )}
        >
          {forgotResult.message}
        </span>
      )}
      {forgotResult?.type !== "success" && (
        <button className="rounded-md p-2">{t("login.resetPassword")}</button>
      )}
    </form>
  );
}
