import dayjs from "@/components/dayjsExtended";

import { Document, DocumentRaw } from "./models/document";
import { Household, HouseholdRaw } from "./models/household";
import { Product, ProductRaw } from "./models/product";

export const enrichDocument = (document: DocumentRaw): Document => {
  const rawPath = document.originalFilename.startsWith("/")
    ? document.originalFilename.slice(1)
    : document.originalFilename;
  const path = rawPath.split("/");
  return {
    ...document,
    folderPath: path.slice(0, path.length - 1),
    fileName: path[path.length - 1],
  };
};

export const enrichHousehold = (household: HouseholdRaw): Household => {
  return {
    ...household,
    warrantyEndDateTimestamp: dayjs(household.warrantyEndDate),
  };
};

export const enrichProduct = (product: ProductRaw): Product => {
  return {
    ...product,
    createdAtTimestamp: dayjs(product.createdAt),
    nextMaintenanceTimestamp: product.nextMaintenance
      ? dayjs(product.nextMaintenance)
      : null,
  };
};
