import { clsx } from "clsx";
import { ReactNode } from "react";

import { useDialogue } from "@/api/hooks/useDialogue.ts";
import UnmanagedPopup from "@/components/common/UnmanagedPopup.tsx";

export default function ManagedPopup({
  cleanup,
  trigger,
  triggerClassname,
  title,
  children,
  minHeight,
  onClose,
}: {
  trigger: ReactNode;
  title: ReactNode;
  children: ReactNode;
  triggerClassname?: string;
  cleanup?: () => void;
  minHeight?: boolean;
  onClose?: () => void;
}) {
  const [isOpen, setIsOpen] = useDialogue(cleanup);

  return (
    <UnmanagedPopup
      trigger={
        <span
          className={clsx("cursor-pointer", triggerClassname)}
          onClick={() => setIsOpen(true, true)}
        >
          {trigger}
        </span>
      }
      title={title}
      isOpen={isOpen}
      closePopup={() => {
        setIsOpen(false, true);
        if (onClose) {
          onClose();
        }
      }}
      minHeight={minHeight}
    >
      {children}
    </UnmanagedPopup>
  );
}
