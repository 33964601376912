import { Dayjs } from "@/components/dayjsExtended.ts";

export interface WarrantyCaseRaw {
  id: number;
  householdId: number;
  title: string;
  status: WarrantyCaseStatus;
  createdAt: string;
}

export interface WarrantyCase extends WarrantyCaseRaw {
  createdAtTimestamp: Dayjs;
  localisedStatus: string;
}

export enum WarrantyCaseStatus {
  QUESTION_SENT = "QUESTION_SENT",
  RESPONSE_RECEIVED = "RESPONSE_RECEIVED",
  SOLVED = "SOLVED",
  CLOSED = "CLOSED",
}

export const statusMap = new Map<WarrantyCaseStatus, string>([
  [WarrantyCaseStatus.QUESTION_SENT, "warranty.caseStatus.questionSent"],
  [
    WarrantyCaseStatus.RESPONSE_RECEIVED,
    "warranty.caseStatus.responseReceived",
  ],
  [WarrantyCaseStatus.SOLVED, "warranty.caseStatus.solved"],
  [WarrantyCaseStatus.CLOSED, "warranty.caseStatus.closed"],
]);

export interface WarrantyCaseMessageRaw {
  id: number;
  warrantyCaseId: number;
  accountId: number;
  userId: number;
  createdAt: string;
  content: string;
  type: WarrantyCaseMessageType;
}

export interface WarrantyCaseMessage extends WarrantyCaseMessageRaw {
  createdAtTimestamp: Dayjs;
}

export enum WarrantyCaseMessageType {
  TEXT = "TEXT",
  FILE = "FILE",
  IMAGE = "IMAGE",
  AI_HISTORY = "AI_HISTORY",
}

export interface WarrantyCaseResponse {
  case: WarrantyCase;
  messages: WarrantyCaseMessage[];
}

export interface WarrantyMessageRequest {
  accountId: number;
  message: string;
}

export interface NewWarrantyCaseRequest {
  question: string;
  householdId: number;
  startFresh: boolean;
  accountId: number;
}

export interface SubmitWarrantyCaseRequestWithoutFiles {
  title: string;
  description: string;
  householdId: number;
  accountId: number;
  phoneNumber: string | null;
}

export interface UpdateWarrantyStatusRequest {
  buildingId: number;
  status: WarrantyCaseStatus;
}

export enum NewWarrantyCaseResponseType {
  MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
  WARRANTY = "WARRANTY",
  NO_WARRANTY = "NO_WARRANTY",
}

export interface NewWarrantyCaseResponse {
  status: NewWarrantyCaseResponseType;
  message: string;
  caseId: number | null;
  provisionalTitle: string | null;
}
