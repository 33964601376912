import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { randomString } from "@/components/common/helper.ts";

export const useDialogue = (cleanup?: () => void) => {
  const id = useMemo(() => randomString(10), []);

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const togglePopup = useCallback(
    (newState: boolean, useNav: boolean) => {
      if (newState) {
        if (useNav) {
          navigate({ hash: id }, { state: { dialogue: id } });
        }
        setIsOpen(true);
      } else {
        if (useNav) {
          navigate(-1);
        }
        setIsOpen(false);
        if (cleanup) {
          cleanup();
        }
      }
    },
    [setIsOpen, cleanup, navigate, id],
  );

  useEffect(() => {
    const effectCallback = (event: PopStateEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event.state?.usr?.dialogue !== id) {
        togglePopup(false, false);
      }
    };
    window.addEventListener("popstate", effectCallback);
    return () => {
      window.removeEventListener("popstate", effectCallback);
    };
  }, [togglePopup, id]);

  return [isOpen, togglePopup] as const;
};
