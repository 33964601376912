import { useEffect } from "react";
import { create } from "zustand";

import { AccountInfo } from "../rest/models/account";
import { fetchAccountInfo } from "../rest/services/accountService";

interface AccountState {
  account: AccountInfo | null;
}

interface AccountActions {
  load: () => Promise<void>;
  clear: () => void;
}

export const useAccountGlobal = create<AccountState & AccountActions>((set) => {
  return {
    account: null,
    load: async () => {
      const account = await fetchAccountInfo();
      if (account.success) {
        set({ account: account.result });
      }
    },
    clear: () => {
      set({ account: null });
    },
  };
});

export const useAccount = () => {
  const account = useAccountGlobal((state) => state.account);
  const load = useAccountGlobal((state) => state.load);

  // Use useEffect with proper dependencies
  useEffect(() => {
    if (account === null) {
      // Prevent unnecessary load calls
      const loadData = async () => {
        await load();
      };
      loadData();
    }
  }, [account, load]);

  return account;
};
