import { useAccountGlobal } from "@/api/hooks/useAccount";
import { useHouseholdsGlobal } from "@/api/hooks/useHouseholds";
import { fetchUsingApi, routerInstance } from "@/api/rest/core/apiClient";
import { ApiResponse } from "@/api/rest/core/types";
import i18n from "@/i18n";
import { removeToken, setToken } from "@/utils/tokenManager";

import { sendMessageToNative } from "@/api/react-native/bridge";

import { apiBase, fetchWithAuth } from "../core/apiClient";
import {
  ChangeEmailRequest,
  ChangeLanguageRequest,
  ChangePasswordRequest,
  ConfirmEmailChangeRequest,
  ConfirmEmailChangeResult,
  ForgotPasswordRequest,
  GoogleLoginRequest,
  LoginRequest,
  ResetPasswordRequest,
} from "../models/auth";

const handleAuthRequest = async (
  url: string,
  request: LoginRequest | GoogleLoginRequest,
): Promise<boolean> => {
  try {
    removeToken();
    const result = await fetch(`${apiBase}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(request),
    });

    if (result.status === 200) {
      const data = await result.json();
      sendMessageToNative({
        type: "LOGIN",
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      });
      setToken(data.accessToken);
      return true;
    }

    // Handle different status codes
    if (result.status === 401 || result.status === 403) {
      console.log("1", result);
      throw new Error(i18n.t("login.loginError"));
    }

    throw new Error(i18n.t("common.error.500"));
  } catch (e) {
    console.log("2", e);
    // If it's our error, rethrow it
    if (e instanceof Error) {
      throw e;
    }
    // For unexpected errors, throw the generic 500 error
    throw new Error(i18n.t("common.error.500"));
  }
};
export const login = async (request: LoginRequest): Promise<boolean> => {
  return handleAuthRequest("/auth/login", request);
};

export const googleLogin = async (
  request: GoogleLoginRequest,
): Promise<boolean> => {
  return handleAuthRequest("/auth/google", request);
};

export const logout = async (): Promise<boolean> => {
  try {
    sendMessageToNative({
      type: "LOGOUT",
    });

    // Clear local storage and state
    removeToken();
    useAccountGlobal.getState().clear();
    useHouseholdsGlobal.getState().clear();

    // Navigate to login
    routerInstance?.navigate("/login");

    return true;
  } catch (error) {
    console.error("Logout error:", error);
    routerInstance?.navigate("/login");
    return false;
  }
};

export const changePassword = async (
  request: ChangePasswordRequest,
): Promise<boolean> => {
  let result;
  try {
    result = await fetchWithAuth(`${apiBase}/auth/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
  if (result.status === 200) {
    return true;
  }
  throw i18n.t("common.error.500");
};

export const forgotPassword = async (
  request: ForgotPasswordRequest,
): Promise<boolean> => {
  let result;
  try {
    result = await fetchWithAuth(`${apiBase}/auth/forgot-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
  if (result.status === 200) {
    return true;
  }
  throw i18n.t("common.error.500");
};

export const resetPassword = async (
  request: ResetPasswordRequest,
): Promise<boolean> => {
  let result;
  try {
    result = await fetchWithAuth(`${apiBase}/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
  if (result.status === 200) {
    return true;
  }
  throw i18n.t("common.error.500");
};

export const changeEmail = async (
  request: ChangeEmailRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-email`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};

export const confirmEmailChange = async (
  request: ConfirmEmailChangeRequest,
): Promise<ApiResponse<ConfirmEmailChangeResult>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/confirm-email-change`,
    "POST",
    (e) => e as ConfirmEmailChangeResult,
    JSON.stringify(request),
  );
};

export const changeLanguage = async (
  request: ChangeLanguageRequest,
): Promise<ApiResponse<boolean>> => {
  return await fetchUsingApi(
    `${apiBase}/auth/change-language`,
    "POST",
    () => true,
    JSON.stringify(request),
  );
};
