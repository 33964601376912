import { groupBy, keyBy } from "lodash";

import i18n from "@/i18n";

import { apiBase, fetchUsingApi, fetchWithAuth } from "../core/apiClient";
import { ApiResponse } from "../core/types";
import { AssignAccountRequest, AssignAccountResponse } from "../models/account";
import {
  HouseholdData,
  HouseholdOwnership,
  HouseholdWithOwnership,
} from "../models/household";
import { enrichDocument, enrichHousehold, enrichProduct } from "../utils";

const convertHouseholdOwnership = (
  data: HouseholdOwnership,
): HouseholdWithOwnership[] => {
  const accountsById = keyBy(data.accounts, (account) => account.id);
  const accountsByHousehold = groupBy(
    data.relations,
    (relation) => relation.householdId,
  );
  return data.households.map((household) => {
    return {
      ...household,
      accounts: (accountsByHousehold[household.id] ?? []).map(
        (relation) => accountsById[relation.accountId],
      ),
    };
  });
};

export const fetchAllHouseholds = async (): Promise<
  ApiResponse<HouseholdWithOwnership[]>
> => {
  return await fetchUsingApi(
    `${apiBase}/household/v1/list`,
    "GET",
    convertHouseholdOwnership,
  );
};

export const fetchHouseholdData = async (
  householdId: number,
): Promise<ApiResponse<HouseholdData>> => {
  return await fetchUsingApi(
    `${apiBase}/household/v1/overview/${householdId}`,
    "GET",
    convertHouseholdData,
  );
};

const convertHouseholdData = (data: HouseholdData): HouseholdData => {
  const products = data.products.map(enrichProduct);
  const documents = data.documents.map(enrichDocument);
  const household = enrichHousehold(data.household);
  return {
    ...data,
    household,
    products,
    documents,
  };
};

export const assignUser = async (
  householdId: number,
  assignRequest: AssignAccountRequest,
): Promise<AssignAccountResponse> => {
  const result = await fetchWithAuth(
    `${apiBase}/household/v1/assign/${householdId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(assignRequest),
    },
  );

  try {
    return (await result.json()) as unknown as AssignAccountResponse;
  } catch (e) {
    console.log("1", e);
    throw i18n.t("common.error.500");
  }
};

export const unassignUser = async (
  householdId: number,
  accountId: number,
): Promise<void> => {
  const result = await fetchWithAuth(
    `${apiBase}/household/v1/assign/${householdId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountId,
      }),
    },
  );
  if (result.status === 200) {
    return;
  }
  throw i18n.t("common.error.500");
};
