import {
  apiBase,
  fetchUsingApi,
  fetchWithAuth,
} from "@/api/rest/core/apiClient";

import i18n from "@/i18n.tsx";

import { ApiResponse } from "../core/types";
import { Document } from "../models/document";

export const deleteDocument = async (
  buildingId: number,
  householdId: number,
  documentId: number,
): Promise<void> => {
  const result = await fetchWithAuth(
    `${apiBase}/buildings/${buildingId}/households/${householdId}/documents/${documentId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (result.status === 200) {
    return;
  }
  throw i18n.t("common.error.500");
};

export const uploadDocuments = async (
  householdId: number,
  files: File[],
): Promise<ApiResponse<boolean>> => {
  const formData = new FormData();
  formData.append("householdId", householdId.toString());
  files.forEach((file) => {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  });
  return await fetchUsingApi(
    `${apiBase}/documents/v1/upload`,
    "POST",
    (e) => e as boolean,
    formData,
  );
};

export const noJSDownload = (url: string, filename: string | null) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename || ""; // Empty string will use server's filename
  link.target = "_blank";
  link.rel = "noopener noreferrer";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function addDownloadParam(url: string): string {
  const urlObj = new URL(url);
  urlObj.searchParams.set("download", "true");
  return urlObj.toString();
}

export const downloadDocument2 = async (
  url: string,
  originalName: string | null,
) => {
  url = addDownloadParam(url);
  try {
    // Check if we're in a WebView environment
    if (window.ReactNativeWebView) {
      noJSDownload(url, originalName);
    } else {
      // For web browsers, use the original blob approach
      const result = await fetchWithAuth(url, {
        method: "GET",
      });
      const data = await result.blob();
      const blobUrl = URL.createObjectURL(data);
      const a = document.createElement("a");
      if (originalName) {
        a.download = originalName;
      }
      a.target = "_blank";
      a.href = blobUrl;
      a.click();
      URL.revokeObjectURL(blobUrl); // Clean up the blob URL
    }
  } catch (error) {
    console.error("Download error:", error);
    throw i18n.t("common.error.500");
  }
};

export const loadDocument = async (doc: Document): Promise<string> => {
  const result = await fetchWithAuth(getDocumentUrl2(doc), {
    method: "GET",
  });
  if (result.status === 200) {
    return await result.text();
  }
  throw i18n.t("common.error.500");
};

export const getDocumentUrl2 = (
  doc: Document | null,
  useHash = false,
  value?: string,
) => {
  if (!doc && !value) {
    throw new Error("Neither document nor custom value provided");
  }
  if (useHash) {
    return `${apiBase}/documents/v1/download/hash/${doc?.hash ?? value}`;
  }
  return `${apiBase}/documents/v1/download/id/${doc?.id ?? value}`;
};
