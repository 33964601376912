import { Bars4Icon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import { memo } from "react";

import { HouseholdData } from "@/api/rest/models/household";
import DobuLogo from "@/assets/dobu-logo.svg";

import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

import CapitalMillLogo from "../assets/partner-logos/capital-mill.svg";
import DorpatLogo from "../assets/partner-logos/dorpat.svg";
import EmbachLogo from "../assets/partner-logos/embach.svg";
import HepsorLogo from "../assets/partner-logos/hepsor.svg";
import LivenLogo from "../assets/partner-logos/liven.svg";
import RaemoisaLogo from "../assets/partner-logos/raemoisa.svg";
import ReterraLogo from "../assets/partner-logos/reterra.svg";

// Define partner logo mapping at module level to prevent recreation
const LogoMappings = {
  EMBACH: [EmbachLogo],
  LIVEN: [LivenLogo],
  HEPSOR: [HepsorLogo],
  RETERRA: [ReterraLogo],
  RETERRA_RAEMOISA: [ReterraLogo, RaemoisaLogo],
  CAPITAL_MILL: [CapitalMillLogo],
  DORPAT: [DorpatLogo],
} as const;

type NavbarLogoProps = {
  householdData?: HouseholdData;
  onToggleMenu: () => void;
};

export const NavbarLogo = memo(function NavbarLogo({
  householdData,
  onToggleMenu,
}: NavbarLogoProps) {
  const navigate = useNavigate();

  // Get logo mappings only if we have partner code
  const logoMappings =
    householdData?.building.partnerCode &&
    LogoMappings[
      householdData.building.partnerCode as keyof typeof LogoMappings
    ];

  const companyLogo = logoMappings?.[0];
  const developmentLogo = logoMappings?.[1];

  useDebugLifecycle("Navbar Logo", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <div className="flex flex-col mt-8 mb-4 gap-2 items-stretch md:items-center">
      <div className="flex flex-row justify-between items-center">
        <Bars4Icon className="h-10 w-10 ml-6 p-1 inline md:hidden invisible" />
        <img
          className="invert mx-4 self-center cursor-pointer"
          src={DobuLogo}
          alt="Dobu Logo"
          width={100}
          height={24}
          onClick={() => navigate("/household")}
        />
        {companyLogo && (
          <img
            className="hidden sm:flex md:hidden mx-4 self-center"
            src={companyLogo}
            alt="Company Logo"
            width={100}
            height={24}
          />
        )}
        {companyLogo && (
          <Bars4Icon
            className="h-10 min-w-10 md:min-w-0 w-10 mr-6 p-1 inline md:hidden cursor-pointer hover:bg-gray-100 hover:text-dobu-orange-focus rounded md:invisible"
            onClick={onToggleMenu}
          />
        )}
        {developmentLogo && (
          <img
            className="hidden sm:flex md:hidden mx-4 self-center"
            src={developmentLogo}
            alt="Development Logo"
            width={100}
            height={24}
          />
        )}
      </div>
      {companyLogo && (
        <hr className="hidden md:flex border-[0.5px] border-solid border-gray-300 rounded-lg w-1/2" />
      )}
      {companyLogo && (
        <div className="hidden md:flex flex-row justify-between items-center">
          <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible" />
          <img
            className="mx-4 self-center"
            src={companyLogo}
            alt="Company Logo"
            width={100}
            height={24}
          />
          <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible" />
        </div>
      )}
      {developmentLogo && (
        <div className="hidden md:flex flex-row justify-between items-center">
          <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible" />
          <img
            className="mx-4 self-center"
            src={developmentLogo}
            alt="Development Logo"
            width={100}
            height={24}
          />
          <Bars4Icon className="h-10 w-10 ml-6 p-1 invisible" />
        </div>
      )}
    </div>
  );
});
