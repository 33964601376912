import { useCallback, useEffect, useState } from "react";

import {
  EntityConfiguration,
  getBuildingConfiguration,
} from "@/api/rest/services/configurationService";

export const useBuildingConfiguration = (builingId: number) => {
  const [configuration, setConfiguration] = useState<EntityConfiguration[]>([]);

  const fetchData = useCallback(async () => {
    const data = await getBuildingConfiguration(builingId);
    setConfiguration(data.success ? data.result : []);
  }, [builingId]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, builingId]);

  return [configuration, fetchData] as const;
};
