import { Dayjs } from "@/components/dayjsExtended.ts";

import { Building } from "./building.ts";

export interface Account {
  id: number;
  name: string;
  type: AccountType;
  country: string;
  registryCode: string;
}

export enum AccountType {
  ORGANIZATION = "ORGANIZATION",
  INDIVIDUAL = "INDIVIDUAL",
}

export interface AccountInfoRaw {
  id: number;
  email: string;
  name: string;
  createdAt: string;
  authorities: string[];
  language: string;
  accounts: AccountInfoAccount[];
}

export interface AccountInfoRaw {
  id: number;
  email: string;
  name: string;
  createdAt: string;
  authorities: string[];
  language: string;
  accounts: AccountInfoAccount[];
}

export interface AccountInfo extends AccountInfoRaw {
  createdAtTimestamp: Dayjs;
}

export interface AccountInfoAccount extends Account {
  managedBuildings: Building[];
}

export interface AssignAccountRequest {
  type: AccountType;
  country: string;
  registryCode: string;
  additional?: AssignAccountRequestAdditional;
}

export interface AssignAccountRequestAdditional {
  name: string;
  email: string;
}

export enum AssignAccountResponse {
  SUCCESS = "SUCCESS",
  MORE_INFO_REQUIRED = "MORE_INFO_REQUIRED",
}
