import { apiBase, fetchUsingApi } from "../core/apiClient";
import { Room } from "../models/room";

export const createRoom = async (
  householdId: number,
  name: string,
): Promise<Room> => {
  const response = await fetchUsingApi<Room>(
    `${apiBase}/room/v1/create`,
    "POST",
    (json: any) => json as Room,
    JSON.stringify({
      householdId,
      name,
    }),
  );

  if (response.success) {
    return response.result;
  }
  throw response.message;
};

export const deleteRoom = async (
  householdId: number,
  roomId: number,
): Promise<void> => {
  const response = await fetchUsingApi<void>(
    `${apiBase}/room/v1/remove`,
    "DELETE",
    () => undefined,
    JSON.stringify({
      householdId,
      roomId,
    }),
  );

  if (response.success) {
    return;
  }
  throw response.message;
};
