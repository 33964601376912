import { useEffect, useRef } from "react";

interface DebugLifecycleOptions {
  // Enable/disable debug logging
  enabled?: boolean;
  // Additional data to log with lifecycle events
  data?: Record<string, unknown>;
  // Custom logger function (defaults to console.log)
  logger?: (message: string, ...args: any[]) => void;
}

/**
 * Hook for debugging component lifecycle events
 * @param componentName - Name of the component being debugged
 * @param options - Configuration options for debugging
 */
export const useDebugLifecycle = (
  componentName: string,
  options: DebugLifecycleOptions = {},
) => {
  const {
    enabled = import.meta.env.MODE === "development",
    data = {},
    logger = console.log,
  } = options;

  // Use ref to track render count
  const renderCount = useRef(0);

  // Increment render count on each render
  renderCount.current += 1;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const timestamp = new Date().toISOString();
    const debugData = {
      timestamp,
      renderCount: renderCount.current,
      ...data,
    };

    logger(`[Lifecycle][${componentName}] Mounted`, debugData);

    return () => {
      logger(`[Lifecycle][${componentName}] Unmounted`, {
        ...debugData,
        timestamp: new Date().toISOString(),
        mountDuration: `${Date.now() - new Date(timestamp).getTime()}ms`,
      });
    };
  }, [componentName, enabled, logger, data]);

  // Log re-renders in development
  if (enabled && renderCount.current > 1) {
    logger(
      `[Lifecycle][${componentName}] Re-render #${renderCount.current}`,
      data,
    );
  }

  // Return render count for optional use in components
  return renderCount.current;
};
