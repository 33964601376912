import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAccount } from "@/api/hooks/useAccount";
import { useEnrichedHouseholds } from "@/api/hooks/useEnrichedHouseholds";
import { useHousehold } from "@/api/hooks/useHousehold";
import { logout } from "@/api/rest/services/authService";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

import { NavbarLogo } from "./NavbarLogo";
import { NavbarMenu } from "./NavbarMenu";

// Memoize child components to prevent unnecessary re-renders
const MemoizedNavbarLogo = memo(NavbarLogo);
const MemoizedNavbarMenu = memo(NavbarMenu);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const account = useAccount();
  const households = useEnrichedHouseholds();
  const { householdId } = useParams<{ householdId: string }>();

  // Use useMemo for expensive computations
  const [currentHouseholdData] = useHousehold(
    householdId ? Number(householdId) : null,
  );

  const handleToggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleNavigate = useCallback(
    (path: string) => {
      setIsOpen(false);
      navigate(path);
    },
    [navigate],
  );

  const handleLogout = useCallback(async () => {
    await logout();
  }, []);

  // Memoize props being passed to child components
  const logoProps = useMemo(
    () => ({
      householdData: currentHouseholdData,
      onToggleMenu: handleToggleMenu,
    }),
    [currentHouseholdData, handleToggleMenu],
  );

  const menuProps = useMemo(
    () => ({
      isOpen,
      account,
      households,
      onNavigate: handleNavigate,
      onLogout: handleLogout,
    }),
    [isOpen, account, households, handleNavigate, handleLogout],
  );

  useDebugLifecycle("Navbar", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <nav className="min-w-[240px] flex flex-col justify-stretch bg-white">
      <MemoizedNavbarLogo {...logoProps} />
      <MemoizedNavbarMenu {...menuProps} />
    </nav>
  );
};

// Memoize the entire Navbar component
export default memo(Navbar);
