import { clsx } from "clsx";
import { ReactNode, Ref } from "react";
import Markdown from "react-markdown";

export function ChatMessageHolder({
  children,
  className,
  scrollRef,
}: {
  children: ReactNode;
  className?: string;
  scrollRef?: Ref<HTMLDivElement>;
}) {
  return (
    <div
      className={clsx(
        "flex flex-col overflow-y-auto gap-2 p-2 scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100",
        className,
      )}
      ref={scrollRef}
    >
      {children}
    </div>
  );
}

export enum ChatMessageType {
  QUESTION = "QUESTION",
  RESPONSE = "RESPONSE",
  RESPONSE_IN_PROCESS = "RESPONSE_IN_PROCESS",
}

export function ChatMessage({
  type,
  colorType,
  className,
  message,
  children,
}: {
  type: ChatMessageType;
  colorType?: ChatMessageType;
  message?: string;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <div
      className={clsx(
        "p-2 rounded max-w-[80%] gap-6 prose prose-sm prose-neutral prose-img:m-0",
        className,
        {
          "self-start":
            type === ChatMessageType.RESPONSE ||
            type === ChatMessageType.RESPONSE_IN_PROCESS,
          "self-end": type === ChatMessageType.QUESTION,
          "bg-orange-200":
            colorType === ChatMessageType.RESPONSE ||
            (!colorType && type === ChatMessageType.RESPONSE),
          "bg-slate-200":
            colorType === ChatMessageType.QUESTION ||
            (!colorType && type === ChatMessageType.QUESTION),
          "bg-orange-100":
            colorType === ChatMessageType.RESPONSE_IN_PROCESS ||
            (!colorType && type === ChatMessageType.RESPONSE_IN_PROCESS),
        },
      )}
    >
      {message && <Markdown>{message}</Markdown>}
      {children}
    </div>
  );
}
