import { ReactNode } from "react";

export const Popover = ({
  children,
  target,
}: {
  children: ReactNode;
  target: ReactNode;
}) => {
  return (
    <span className="relative flex flex-grow flex-row">
      <span className="peer cursor-help">{target}</span>
      <div className="hidden peer-hover:flex text-sm absolute top-full bg-gray-800 bg-opacity-50 text-white z-50 rounded p-2 mt-1">
        {children}
      </div>
    </span>
  );
};
