import { SparklesIcon } from "@heroicons/react/24/outline";

import { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { ApiResponse } from "@/api/rest/core/types";
import { uploadDocuments } from "@/api/rest/services/documentService";
import UploadFilesPopup from "@/components/UploadFilesPopup.tsx";

export default function UploadHouseholdFilesPopup({
  householdId,
  updateHousehold,
}: {
  householdId: number;
  updateHousehold: () => void;
}) {
  const { t } = useTranslation();
  const uploadFiles = useCallback(
    async (files: File[]): Promise<ApiResponse<boolean>> => {
      return await uploadDocuments(householdId, files);
    },
    [householdId],
  );

  return (
    <UploadFilesPopup
      className="inline-flex flex-grow lg:flex-grow-0"
      uploadCallback={uploadFiles}
      updateHousehold={updateHousehold}
    >
      <button className="funky-background rounded-md py-2 pr-4 h-full flex-grow">
        <SparklesIcon className="w-4 h-4 inline -mt-1 ml-4 mr-1" />
        {t("product.addProductFiles")}
      </button>
    </UploadFilesPopup>
  );
}
