import { useCallback, useEffect, useState } from "react";

import { Maintenance } from "../rest/models/maintenance";
import { fetchMaintenance } from "../rest/services/maintenanceService";

export const useMaintenance = (productId: number | null) => {
  const [maintenanceData, setMaintenanceData] = useState<Maintenance>();

  const fetchData = useCallback(async () => {
    if (!productId) {
      return;
    }
    const data = await fetchMaintenance(productId);
    if (data.success) {
      setMaintenanceData(data.result);
    }
  }, [productId]);

  useEffect(() => {
    fetchData().then(null);
  }, [fetchData, productId]);

  return [maintenanceData, fetchData] as const;
};
