import dayjs from "@/components/dayjsExtended.ts";

import { apiBase, fetchUsingApi } from "../core/apiClient";
import { ApiResponse } from "../core/types";
import {
  Maintenance,
  MaintenanceHistory,
  MaintenanceHistoryRaw,
} from "../models/maintenance";
import { enrichDocument } from "../utils";

export const fetchMaintenance = async (
  productId: number,
): Promise<ApiResponse<Maintenance>> => {
  return await fetchUsingApi(
    `${apiBase}/product/v1/${productId}/maintenance`,
    "GET",
    convertMaintenanceData,
  );
};

const enrichMaintenanceHistory = (
  maintenanceHistory: MaintenanceHistoryRaw,
): MaintenanceHistory => {
  return {
    ...maintenanceHistory,
    performedAtTimestamp: dayjs(maintenanceHistory.performedAt),
    scheduledForTimestamp: dayjs(maintenanceHistory.scheduledFor),
    document:
      maintenanceHistory.document &&
      enrichDocument(maintenanceHistory.document),
  };
};

const convertMaintenanceData = (data: Maintenance): Maintenance => {
  return {
    ...data,
    nextMaintenanceTimestamp: dayjs(data.nextMaintenance),
    history: data.history.map(enrichMaintenanceHistory),
  };
};

export const markMaintenanceDone = async (
  productId: number,
  file: File | null,
): Promise<ApiResponse<void>> => {
  const formData = new FormData();
  if (file) {
    formData.append("files", file);
    formData.append("fileNames", file.path);
  }
  return await fetchUsingApi(
    `${apiBase}/product/v1/${productId}/maintenance-done`,
    "POST",
    () => undefined,
    formData,
  );
};
