import { clsx } from "clsx";
import { MouseEventHandler, ReactNode } from "react";

import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

export const KeyV = ({
  name,
  value,
  icon,
  onClick,
  forceOnClick,
  className,
}: {
  name: string;
  value?: string;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  forceOnClick?: boolean;
  className?: string;
}) => {
  useDebugLifecycle("KeyV", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <div
      className={clsx(
        "bg-slate-100 flex flex-col py-2 px-4 rounded-md min-w-24",
        (onClick ?? forceOnClick) && "hover:bg-orange-100 cursor-pointer",
        className,
      )}
      onClick={onClick}
    >
      <span className="font-semibold break-all">
        {icon} {name}
      </span>
      {value && <span className="whitespace-pre-wrap">{value}</span>}
    </div>
  );
};
