import { GoogleLogin } from "@react-oauth/google";

import { useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import { googleLogin } from "@/api/rest/services/authService.ts";
import { AuthType } from "@/app/login/LoginType.ts";
import AboutForm from "@/components/password-forms/AboutForm.tsx";
import ConfirmEmailChangeForm from "@/components/password-forms/ConfirmEmailChangeForm.tsx";
import ForgotPasswordForm from "@/components/password-forms/ForgotPasswordForm.tsx";
import ResetPasswordForm from "@/components/password-forms/ResetPasswordForm.tsx";

import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle.ts";

import { GoogleSignInButton } from "@/components/auth/GoogleSignInButton.tsx";

import { sendMessageToNative } from "@/api/react-native/bridge.ts";

import DobuLogo from "../../assets/dobu-logo.svg";
import HouseOutline from "../../assets/house-outline.svg";
import LoginForm from "../../components/password-forms/LoginForm.tsx";

export default function Login({ authType }: { authType: AuthType }) {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const isWebView = Boolean(window.ReactNativeWebView);

  const handleGoogleLoginSuccess = async (response: any) => {
    setError(null);
    try {
      const result = await googleLogin({ credential: response.credential });
      if (result) {
        navigate("/household");
      }
    } catch (e) {
      console.log(e);
      setError(String(e));
    }
  };

  window.handleGoogleSignInResponse = async (credential: string) => {
    try {
      const result = await googleLogin({ credential: credential });
      if (result) {
        navigate("/household");
      }
    } catch (e) {
      console.error(e);
      setError(String(e));
    }
  };

  const handleNativeGoogleSignIn = () => {
    sendMessageToNative({
      type: "GOOGLE_SIGN_IN_REQUEST",
    });
  };

  useDebugLifecycle("LoginPage", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-4 md:p-24">
      <div className="flex flex-col md:grid md:grid-cols-2 rounded-lg overflow-clip bg-white md:shadow-2xl max-w-4xl w-full">
        <div className="md:bg-dobu-orange pt-8 px-8 md:p-8 flex flex-col items-center justify-between md:flex-grow">
          <div className="flex flex-col flex-grow w-full items-center">
            <img
              className="md:invert"
              src={HouseOutline}
              alt={"Illustration of a house"}
            />
          </div>
          <img
            className="hidden md:block mb-4 mt-8"
            src={DobuLogo}
            alt={"Logo"}
            width={100}
            height={24}
          />
        </div>
        <div className="pb-8 px-8 md:p-8 flex flex-col items-center justify-around">
          {authType === AuthType.LOGIN && (
            <h1 className="text-3xl font-bold">{t("login.login")}</h1>
          )}
          {authType === AuthType.FORGOT_PASSWORD && (
            <h1 className="text-3xl font-bold text-center">
              {t("login.forgotPassword")}
            </h1>
          )}
          {authType === AuthType.RESET_PASSWORD && (
            <h1 className="text-3xl font-bold">{t("login.password")}</h1>
          )}
          {authType === AuthType.CHANGE_EMAIL && (
            <h1 className="text-3xl font-bold">{t("login.email")}</h1>
          )}
          {authType === AuthType.ABOUT && (
            <h1 className="text-3xl font-bold text-center">
              <Trans i18nKey="login.noAccountHeader" />
            </h1>
          )}
          {authType === AuthType.LOGIN && (
            <span className="text-gray-500 mt-2 mb-4 text-center">
              <Trans i18nKey="login.noAccount" />
              <a
                className="text-dobu-orange hover:text-dobu-orange-focus font-bold cursor-pointer"
                onClick={() => navigate("/login/about")}
              >
                {t("login.seeMore")}
              </a>
            </span>
          )}
          {(authType === AuthType.ABOUT ||
            authType === AuthType.RESET_PASSWORD) && (
            <span className="text-gray-500 mt-2 text-center">
              <Trans i18nKey="login.alreadyHaveAccount" />
              <a
                className="text-dobu-orange hover:text-dobu-orange-focus font-bold cursor-pointer"
                onClick={() => navigate("/login")}
              >
                {t("login.login")}
              </a>
            </span>
          )}
          {authType === AuthType.LOGIN && (
            <div className="py-4 flex justify-center">
              {isWebView ? (
                <GoogleSignInButton onClick={handleNativeGoogleSignIn} />
              ) : (
                <div className="google-oauth-button">
                  <GoogleLogin onSuccess={handleGoogleLoginSuccess} />
                </div>
              )}
            </div>
          )}
          {authType === AuthType.LOGIN && error && (
            <span className="bg-red-300 rounded-lg p-4 text-center text-red-900 text-md">
              {error}
            </span>
          )}
          {authType === AuthType.LOGIN && (
            <div
              className="rounded-lg self-stretch flex items-center text-base
                        text-gray-400 lowercase before:flex-1 mx-12
                        before:border-t before:border-gray-300 before:me-3 after:flex-1
                        after:border-t after:border-gray-300 after:ms-3"
            >
              {t("login.or")}
            </div>
          )}
          {authType === AuthType.LOGIN && (
            <LoginForm className="flex flex-col gap-4 self-stretch px-8 py-4" />
          )}
          {authType === AuthType.ABOUT && (
            <AboutForm className="flex flex-col gap-4 self-stretch px-4 py-4" />
          )}
          {authType === AuthType.FORGOT_PASSWORD && (
            <ForgotPasswordForm className="flex flex-col gap-4 self-stretch px-8 py-4" />
          )}
          {authType === AuthType.RESET_PASSWORD && (
            <ResetPasswordForm className="flex flex-col gap-4 self-stretch px-8 py-4" />
          )}
          {authType === AuthType.CHANGE_EMAIL && (
            <ConfirmEmailChangeForm className="flex flex-col gap-4 self-stretch px-8 py-4" />
          )}
          <img
            className="invert mb-4 mt-8"
            src={DobuLogo}
            alt={"Logo"}
            width={100}
            height={24}
          />
        </div>
      </div>
    </main>
  );
}
