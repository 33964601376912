import { Router } from "@remix-run/router/dist/router";

import { getToken, removeToken, setToken } from "@/utils/tokenManager";

import { ApiResponse, AuthResponse, CustomRequestInit } from "./types";

const isAnroidMobile = /Android/i.test(navigator.userAgent);

export const apiBase =
  import.meta.env.MODE === "production"
    ? "https://api.dobu.ee"
    : isAnroidMobile
      ? "http://10.0.2.2:8080"
      : "http://localhost:8080";

export let routerInstance: Router | null = null;
export const setRouterInstance = (router: Router) => {
  routerInstance = router;
};

export const fetchUsingApi = async <Type>(
  url: string,
  method: string,
  resultProcessingFunction: (result: any) => Type,
  body?: BodyInit | null | undefined,
): Promise<ApiResponse<Type>> => {
  try {
    const headers: Record<string, string> = {};

    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const response = await fetchWithAuth(url, {
      method,
      headers,
      body,
    });

    if (response.ok) {
      // Check if the response has content
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const json = await response.json();
        const result = resultProcessingFunction(json);
        return {
          success: true,
          result,
        };
      }
      // Handle empty response (typical for DELETE)
      const result = resultProcessingFunction(null);
      return {
        success: true,
        result,
      };
    }
    // Handle non-ok response
    return {
      success: false,
      message: `HTTP error! status: ${response.status}`,
    };
  } catch (e) {
    // if (e instanceof Error && e.message === "Authentication failed") {
    //   // Handle authentication failure
    //   // removeToken();
    //   // routerInstance?.navigate("/login");
    //   console.log(e);
    //   return
    // }
    return {
      success: false,
      message: String(e),
    };
  }
};

export const fetchWithAuth = async (
  url: string,
  options: CustomRequestInit = {},
): Promise<Response> => {
  const accessToken = getToken();

  // Ensure credentials are included to send/receive cookies
  const fetchOptions: CustomRequestInit = {
    ...options,
    credentials: "include",
    headers: {
      // Ensure headers are always an object
      ...(options.headers || {}),
      // Add access token to Authorization header if it exists
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (response.status === 401 || response.status === 403) {
      // Try to refresh the token
      const refreshed = await refreshToken();

      if (refreshed) {
        // Get the new access token
        const newAccessToken = getToken();
        if (!newAccessToken) {
          throw new Error("New access token not found after refresh");
        }

        return await fetch(url, {
          ...fetchOptions,
          headers: {
            ...(fetchOptions.headers || {}),
            Authorization: `Bearer ${newAccessToken}`,
          },
        });
      }
      // If refresh failed, user needs to login again
      removeToken();
      routerInstance?.navigate("/login");
      throw new Error("Authentication failed");
    }

    return response;
  } catch (error) {
    console.error(
      "Fetch error:",
      error instanceof Error ? error.message : error,
    );
    throw error;
  }
};

// Function to refresh the token
export const refreshToken = async (): Promise<boolean> => {
  try {
    const response = await fetch(`${apiBase}/auth/refresh`, {
      method: "POST",
      credentials: "include",
    });

    if (response.ok) {
      const data = (await response.json()) as AuthResponse;
      if (data.accessToken) {
        setToken(data.accessToken);
        return true;
      }
    }

    return false;
  } catch (error) {
    console.error(
      "Refresh token error:",
      error instanceof Error ? error.message : error,
    );
    return false;
  }
};
