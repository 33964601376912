import dayjs, { Dayjs } from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/et";
import "dayjs/locale/en";
import "dayjs/locale/ru";

dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);
dayjs.locale("et");

export default dayjs;
export { Dayjs };
