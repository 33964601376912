import { ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";

export default function UnmanagedPopup({
  trigger,
  title,
  children,
  minHeight,
  isOpen,
  closePopup,
}: {
  trigger: ReactNode;
  title: ReactNode;
  children: ReactNode;
  minHeight?: boolean;
  isOpen: boolean;
  closePopup: () => void;
}) {
  return (
    <>
      {trigger}
      <Dialog open={isOpen} onClose={closePopup} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center md:p-4">
          <Dialog.Panel
            className={clsx(
              "mx-auto md:rounded-lg bg-white flex flex-col shadow-xl overflow-clip min-w-full min-h-full max-h-full max-w-full md:min-h-0 md:min-w-[50%] md:max-w-[75%] md:max-h-[80vh]",
              minHeight && "md:min-h-[80vh]",
            )}
          >
            <Dialog.Title className="text-lg p-6 bg-slate-100 font-medium leading-6 text-gray-900 flex flex-row justify-between">
              {title}
              <XCircleIcon
                className="h-6 w-6 min-w-6 min-h-6 text-gray-500 hover:text-dobu-orange-focus focus-visible:text-dobu-orange-focus cursor-pointer"
                onClick={closePopup}
              />
            </Dialog.Title>

            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
