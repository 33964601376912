import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { changeLanguage } from "@/api/rest/services/authService";
import { useDebugLifecycle } from "@/utils/debug/useDebugLifecycle";

function LanguageSelector() {
  const { i18n } = useTranslation();

  const allLanguages = useMemo(
    () => [
      { key: "et", value: "Eesti" },
      { key: "en", value: "English" },
    ],
    [],
  );

  // Derive current display value directly from i18n.language
  const currentLanguage = useMemo(
    () =>
      allLanguages.find((lang) => lang.key === i18n.language)?.value ||
      "English",
    [i18n.language, allLanguages],
  );

  const handleLanguageChange = async (language: string) => {
    await i18n.changeLanguage(language);
    dayjs.locale(language);
    await changeLanguage({ language }); // Assuming this is API call to save preference
  };

  useDebugLifecycle("LanguageSelection", {
    data: {
      isWebView: Boolean(window.ReactNativeWebView),
      url: window.location.href,
    },
  });

  return (
    <Menu>
      <MenuButton className="rounded-md py-2 px-4 gap-2 text-white bg-dobu-orange inline-flex items-center">
        {currentLanguage}
        <ChevronDownIcon className="size-5 fill-white/100" />
      </MenuButton>
      <MenuItems
        anchor="bottom"
        className="mt-1 rounded-md border border-dobu-orange/5 bg-white p-1 text-sm/6 text-dobu-black focus:outline-none"
      >
        {allLanguages.map((option) => (
          <MenuItem key={option.key}>
            {option.value === currentLanguage ? (
              <button
                className="bg-dobu-orange text-white group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-dobu-orange-focus data-[focus]:text-white"
                onClick={() => handleLanguageChange(option.key)}
              >
                {option.value}
              </button>
            ) : (
              <button
                className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-dobu-orange-focus data-[focus]:text-white"
                onClick={() => handleLanguageChange(option.key)}
              >
                {option.value}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}

export default LanguageSelector;
