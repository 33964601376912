import i18n from "i18next";
import dayjs from "dayjs";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "et",
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    supportedLngs: ["en", "et", "ru"],
    debug: import.meta.env.MODE === "development",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })
  .then(() => {
    // Set dayjs locale to match i18n language
    dayjs.locale(i18n.language);
  });

export default i18n;
